export const LINE_MARKETS = [
    '1st Innings 6 Overs Line',
    '1st Innings 10 Overs Line',
    '1st Innings 15 Overs Line',
    '1st Innings 20 Overs Line',
    '2nd Innings 6 Overs Line',
    '2nd Innings 10 Overs Line',
    '2nd Innings 15 Overs Line',
    '2nd Innings 20 Overs Line',
];
